import React from 'react';

import { UspListItem } from 'components/UspList/UspListItem';
import { IconType, SVG } from 'components/tokens/Icon';
import { Direction } from 'enums/Direction';

import styles from './UspList.module.scss';

export interface IUspListItem {
  icon: SVG;
  iconType?: IconType;
  heading: string;
  text: string;
  link: string;
}

interface Props {
  listVariant?: Direction;
  itemVariant?: Direction;
  items: IUspListItem[];
}

export const UspList: React.FC<Props> = ({
  listVariant = Direction.Horizontal,
  itemVariant = Direction.Horizontal,
  items,
}) => (
  <div className={`${styles.container} ${styles[listVariant]}`}>
    {items.map((item) => (
      <UspListItem
        icon={item.icon}
        iconType={item.iconType ?? IconType.Stroke}
        heading={item.heading}
        text={item.text}
        variant={itemVariant}
        key={item.heading}
        link={item.link}
      />
    ))}
  </div>
);
