import React from 'react';
import Link from 'next/link';

import { Icon, IconType, SVG } from 'components/tokens/Icon';
import { Colors } from 'enums/Colors';
import { Direction } from 'enums/Direction';
import arrowRight from 'public/assets/icons/default/arrow-right.svg';

import styles from './UspListItem.module.scss';

interface Props {
  icon: SVG;
  iconType?: IconType;
  heading: string;
  text: string;
  variant?: Direction;
  link: string;
}

export const UspListItem: React.FC<Props> = ({
  icon,
  iconType = IconType.Stroke,
  heading,
  text,
  variant = Direction.Horizontal,
  link,
}) => (
  <Link href={link}>
    <div className={`${styles.item} ${styles[variant]}`}>
      <div className={styles.iconAlignment}>
        <Icon icon={icon} size={40} type={iconType} color={Colors.White} />
      </div>
      <div>
        <p className={styles.heading}>{heading}</p>
        <p className={styles.text}>{text}</p>
      </div>
      <div className={styles.iconAlignment}>
        <Icon icon={arrowRight} size={15} color={Colors.White} />
      </div>
    </div>
  </Link>
);
