import React from 'react';

import { BackgroundImage } from 'components/BackgroundImage';

import styles from './Image.module.scss';

interface Props {
  rounded: boolean;
  src: string;
  alt: string;
  title: string;
  intro: string;
}

export const Image: React.FC<Props> = ({ rounded = false, src, alt, title, intro }) => (
  <div className={`${styles.image} ${rounded ? styles.rounded : ''}`}>
    <BackgroundImage src={src} alt={alt} />
    <div className={styles.content}>
      <h2>{title}</h2>
      <h3>{intro}</h3>
    </div>
  </div>
);
